import { useRef, useState } from 'react'

import { encryptionService } from '~/utils/encryption'

export type UploadStatus =
  | 'idle'
  | 'loading'
  | 'success'
  | 'error'
  | 'abort'
  | 'encrypting'
  | 'removed'

interface XhrErrorResponse {
  statusCode: number
  message: string
  error: string
}

export interface UploadFunctionProps {
  /**
   * Path to download from (already appended with /api)
   */
  url: string

  /**
   * File object to upload
   */
  file: File | Blob

  /**
   * Properties to attach to body of upload request
   */
  props: {
    name: string
    sizeInBytes: number
    [key: string]: unknown
  }

  /**
   * Function to run on complete of upload.
   */
  onComplete?: (completion: {
    key: string
    uploadStatus: UploadStatus
  }) => Promise<void> | void

  /**
   * Encryption key
   */
  publicKey: string
}

export interface UploadInfo<TResponse> {
  /**
   * Filename of file object
   */
  fileName: string

  /**
   * Size of file object
   */
  sizeInBytes: number
  /**
   * Function to abort upload
   */
  abort: () => void

  /**
   * Upload progress, 0-100
   */
  uploadProgress: number

  /**
   * Status of upload
   */
  status: UploadStatus

  /**
   * Error object
   */
  error?: { status: number; message: string; error: string }

  /**
   * Response object, whatever upload returns
   */
  info?: TResponse
}

export interface UploadHookInfo<TResponse> {
  /**
   * Async upload mutation to add to queue
   */
  queueEncryptAndUpload: (props: UploadFunctionProps) => string

  /**
   * Results of uploads
   */
  infos: Record<string, UploadInfo<TResponse>>

  setInfo: (key: string, info: Partial<UploadInfo<TResponse>>) => void
}

export interface UploadProps {
  /**
   * True if we want sequential uploads, otherwise all upload run in parallel
   */
  sequentialUploads?: boolean
}

// Hook for uploading a file, with progress indicator
// Based off https://javascript.info/resume-upload and https://javascript.info/xmlhttprequest
// Fetch API still has not implemented streaming body, hence XHR is used here
// TODO: Find out if there's a cleaner way to implement enqueuer hooks
export const useUpload = <TResponse,>({
  sequentialUploads = true,
}: UploadProps): UploadHookInfo<TResponse> => {
  const count = useRef(0) // This is used as a key to track
  const completedCount = useRef(0) // This is used to track of completed for the queue
  const [infos, setInfos] = useState<Record<string, UploadInfo<TResponse>>>({})
  const infosRef = useRef(infos)
  infosRef.current = infos

  const queueEncryptAndUpload = (uploadProps: UploadFunctionProps) => {
    count.current += 1
    const current = count.current // this goes into promise
    const key = `${count.current}`

    const xhr = new XMLHttpRequest()
    const info: UploadInfo<TResponse> = {
      fileName: uploadProps.props.name,
      sizeInBytes: uploadProps.props.sizeInBytes,
      abort: () => {
        setInfo(key, {
          status: 'abort',
          error: {
            message: 'Aborted',
            status: xhr.status,
            error: xhr.statusText,
          },
        })
        completedCount.current += 1
      },
      status: 'idle',
      uploadProgress: 0,
    }

    setInfos((infos) => {
      return { ...infos, [key]: info }
    })

    // Queue upload 1 at a time
    if (sequentialUploads && count.current === 1) {
      void encryptAndUpload({ key, uploadProps, xhr })
    } else {
      void new Promise<void>((resolve) => {
        // Wait for previous upload to complete
        const timer = setInterval(() => {
          // Wait until the total completed is one less than current
          if (completedCount.current >= current - 1) {
            clearInterval(timer)
            resolve()
          }
        }, 1000)
      }).then(() => encryptAndUpload({ key, uploadProps, xhr }))
    }

    return key
  }

  // TODO convert to useReducer
  const setInfo = (key: string, info: Partial<UploadInfo<TResponse>>) => {
    setInfos((infos) => {
      return {
        ...infos,
        [key]: {
          ...infos[key],
          ...info,
        },
      }
    })
  }

  const encryptAndUpload = async ({
    key,
    uploadProps,
    xhr,
  }: {
    key: string
    uploadProps: UploadFunctionProps
    xhr: XMLHttpRequest
  }) => {
    const encryptedContent = await encrypt({ key, uploadProps })
    if (!encryptedContent) return // aborted
    uploadProps.props.encryptedSymmetricKey =
      encryptedContent.encryptedSymmetricKey
    uploadProps.file = encryptedContent.encryptedFile
    uploadProps.props.sizeInBytes = encryptedContent.sizeInBytes
    void upload({ key, uploadProps, xhr })
  }

  const encrypt = async ({
    key,
    uploadProps,
  }: {
    key: string
    uploadProps: UploadFunctionProps
  }) => {
    const { file, publicKey } = uploadProps
    // Check for early abort
    if (infosRef.current[key] && infosRef.current[key].status === 'abort') {
      return
    }

    setInfo(key, {
      status: 'encrypting',
    })
    const symmetricKey = encryptionService.generateSymmetricKey()
    const encryptedFile = await encryptionService.encryptFileSymmetrically(
      symmetricKey,
      file,
    )
    const sizeInBytes = encryptedFile.size
    const encryptedSymmetricKey = encryptionService.encryptKeyAsymmetrically(
      publicKey,
      symmetricKey,
    )
    return {
      encryptedFile,
      encryptedSymmetricKey,
      sizeInBytes,
    }
  }

  // Actual upload function
  const upload = async ({
    key,
    uploadProps,
    xhr,
  }: {
    key: string
    uploadProps: UploadFunctionProps
    xhr: XMLHttpRequest
  }) => {
    return new Promise<void>((resolve, _reject) => {
      // Check for early abort
      if (infosRef.current[key] && infosRef.current[key].status === 'abort') {
        resolve()
        return
      }

      const { url, file, props, onComplete } = uploadProps
      setInfo(key, {
        status: 'loading',
        uploadProgress: 0,
        abort: () => xhr.abort(),
      })

      const formdata = new FormData()
      Object.entries(props).forEach(([key, value]) =>
        formdata.append(key, value as string),
      )
      formdata.append('file', file)

      xhr.onload = () => {
        // Onload is called when client UPLOAD portion is successful
        // Still need to get the server response and parse it based on status
        // as server might reject
        if (xhr.status === 200) {
          setInfo(key, {
            status: 'success',
            uploadProgress: 100,
            info: xhr.response as TResponse,
          })
          if (onComplete)
            void onComplete({
              key,
              // document: xhr.response as Document,
              uploadStatus: 'success',
            })
        } else {
          // All other codes are invalid
          setInfo(key, {
            status: 'error',
            error: {
              message: (xhr.response as XhrErrorResponse).message,
              status: xhr.status,
              error: xhr.statusText,
            },
          })
          if (onComplete)
            void onComplete({
              key,
              uploadStatus: 'error',
            })
        }
        resolve()
      }

      xhr.onabort = () => {
        setInfo(key, {
          status: 'abort',
          error: {
            message: 'Aborted',
            status: xhr.status,
            error: xhr.statusText,
          },
        })
        if (onComplete)
          void onComplete({
            key,
            uploadStatus: 'error',
          })
        resolve()
      }

      xhr.ontimeout = () => {
        setInfo(key, {
          status: 'error',
          error: {
            message: 'Upload timed out',
            status: xhr.status,
            error: xhr.statusText,
          },
        })
        if (onComplete)
          void onComplete({
            key,
            uploadStatus: 'error',
          })
        resolve()
      }

      xhr.onerror = (_e: ProgressEvent<EventTarget>) => {
        setInfo(key, {
          status: 'error',
          error: {
            message: (xhr.response as XhrErrorResponse).message,
            status: xhr.status,
            error: xhr.statusText,
          },
        })
        if (onComplete)
          void onComplete({
            key,
            uploadStatus: 'error',
          })
        resolve()
      }

      xhr.upload.onprogress = (e: ProgressEvent<EventTarget>) => {
        if (e.loaded <= props.sizeInBytes) {
          const percent = Math.round((e.loaded / props.sizeInBytes) * 100)
          setInfo(key, { uploadProgress: Math.max(percent - 1, 0) })
        }

        if (e.loaded == e.total) {
          setInfo(key, { uploadProgress: 99 })
        }
      }

      xhr.responseType = 'json'
      xhr.open('PUT', `/api/${url}`.replace('//', '/'), true)
      xhr.timeout = 1000 * 60 * 30 // time in milliseconds
      xhr.send(formdata)
    }).then(() => (completedCount.current += 1)) // increment completed count
  }

  return {
    queueEncryptAndUpload,
    infos,
    setInfo,
  }
}
