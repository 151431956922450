import { Navigate, useNavigate } from 'react-router-dom'
import { Spinner, Text, VStack } from '@chakra-ui/react'

import { routes } from '~/constants/routes'
import { DocumentsUpload } from '~/features/upload/Upload/DocumentsUpload'

import { RichTextEditor } from '../richtext'

import {
  usePublicSubmission,
  useSubmissionPublicId,
  useUponPublicUpload,
} from './public.hooks'

export const UploadPage = (): JSX.Element => {
  const navigate = useNavigate()
  const { submissionPublicId } = useSubmissionPublicId()
  const { submission, isSubmissionLoading } =
    usePublicSubmission(submissionPublicId)
  const uponPublicUpload = useUponPublicUpload(submissionPublicId)

  if (isSubmissionLoading) return <Spinner />

  //Invalid or expired link, redirect back to public page for handling
  if (!submission)
    return <Navigate to={routes.public.index + '/' + submissionPublicId} />

  return (
    <VStack
      maxW={{ base: '100vw', md: '720px' }}
      align="start"
      spacing={8}
      p={{ base: 6, md: 10 }}
    >
      <VStack align="start">
        <Text textStyle="h5">Instructions</Text>
        <RichTextEditor isReadOnly content={submission.instructions} />
      </VStack>
      <DocumentsUpload
        w="full"
        submissionId={submissionPublicId}
        campaignPublicKey={submission.campaignPublicKey}
        onComplete={() => uponPublicUpload()}
      />
    </VStack>
  )
}
