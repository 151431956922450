import { Navigate, RouteObject } from 'react-router-dom'

import { routes } from '~/constants/routes'
import { UploadPage, WelcomePage } from '~/features/public'
import { ConfirmationPage } from '~/features/public/ConfirmationPage'
import { ErrorPage } from '~/features/public/ErrorPage'
import { PublicLayout } from '~/layouts/PublicLayout'

export const userRoutes: RouteObject[] = [
  {
    path: routes.public.index,
    element: <PublicLayout />,
    children: [
      {
        path: ':submissionPublicId',
        children: [
          {
            index: true,
            element: <WelcomePage />,
          },
          {
            path: 'upload',
            element: <UploadPage />,
          },
          {
            path: 'confirmation',
            element: <ConfirmationPage />,
          },
        ],
      },
      {
        path: 'error',
        element: <ErrorPage />,
      },
      {
        path: '*',
        children: [
          {
            index: true,
            element: <Navigate to={routes.public.error} />,
          },
        ],
      },
    ],
  },
]
