import { useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { GetSubmissionPublicDto } from '~shared/dtos'

import { adminQueryKeys, publicQueryKeys } from '~/constants/query-keys'
import { api } from '~/lib/api'

type ReactQueryError = {
  status: number
}

export const useSubmissionPublicId = () => {
  const { submissionPublicId } = useParams<{ submissionPublicId: string }>()

  if (!submissionPublicId) {
    throw new Error('No submission public id')
  }

  return { submissionPublicId }
}

export const useUponPublicUpload = (submissionPublicId: string) => {
  const queryClient = useQueryClient()
  return () =>
    queryClient.invalidateQueries(
      publicQueryKeys.submission(submissionPublicId),
    )
}

export const usePublicSubmission = (submissionPublicId: string) => {
  const { data, isLoading, error } = useQuery<
    GetSubmissionPublicDto,
    ReactQueryError
  >(
    publicQueryKeys.submission(submissionPublicId),
    () =>
      api
        .url(`/public/submissions/${submissionPublicId}`)
        .get()
        .json<GetSubmissionPublicDto>(),
    { enabled: !!submissionPublicId, retry: 1 },
  )

  if (error && error.status === 403) {
    return {
      submission: data,
      isSubmissionLoading: isLoading,
      isExpired: true,
    }
  }

  return {
    submission: data,
    isSubmissionLoading: isLoading,
    isExpired: false,
  }
}

export const usePublicDeleteDocumentMutation = (
  { submissionPublicId }: { submissionPublicId: string },
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: () => void
    onError?: () => void
  } = {},
) => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ documentId }: { documentId: number }): Promise<void> => {
      return await api
        .url(
          `/public/submissions/${submissionPublicId}/documents/${documentId}`,
        )
        .delete()
        .res()
    },
    {
      onSuccess: async () => {
        // TODO - should optimistically update
        await queryClient.invalidateQueries(
          publicQueryKeys.submission(submissionPublicId),
        )
        // TODO - separate the mutation for admin
        await queryClient.invalidateQueries([adminQueryKeys.base])
        onSuccess?.()
      },
      onError,
    },
  )
}
