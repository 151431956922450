import { Navigate, useNavigate } from 'react-router-dom'
import { Button, Image, Spinner, Text, VStack } from '@chakra-ui/react'

import GreenCheckSvg from '~/assets/GreenCheck.svg'
import { routes } from '~/constants/routes'

import { usePublicSubmission, useSubmissionPublicId } from './public.hooks'

export const ConfirmationPage = (): JSX.Element => {
  const { submissionPublicId } = useSubmissionPublicId()
  const navigate = useNavigate()
  const { submission, isSubmissionLoading } =
    usePublicSubmission(submissionPublicId)

  if (isSubmissionLoading) return <Spinner />

  if (!submission) return <Navigate to={routes.public.error} />

  return (
    <VStack
      maxW={{ base: '100vw', md: '480px' }}
      align="start"
      spacing={8}
      p={{ base: 6, md: 10 }}
    >
      <VStack w="full" align="center">
        <Image src={GreenCheckSvg} aria-hidden />
        <Text textStyle="h2">You are all done!</Text>
      </VStack>
      <Button
        variant="outline"
        alignSelf={{ base: 'stretch', md: 'center' }}
        w={{ base: 'unset', md: '50%' }}
        onClick={() => navigate('../upload')}
      >
        Add More Files
      </Button>
    </VStack>
  )
}
