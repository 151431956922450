import { BiPaperPlane, BiPencil } from 'react-icons/bi'
import {
  ButtonGroup,
  ButtonGroupProps,
  IconButton,
  Tooltip,
} from '@chakra-ui/react'

import { GetSubmissionDto } from '~shared/dtos'

import { DownloadButton } from '~/features/download'

export const SubmissionActionButtons = ({
  submission,
  onShare,
  onEdit,
  isDisabled = false,
  ...rest
}: {
  submission: GetSubmissionDto
  onShare: (s: GetSubmissionDto) => void
  onEdit: (s: GetSubmissionDto) => void
  isDisabled?: boolean
} & ButtonGroupProps) => {
  return (
    <ButtonGroup {...rest}>
      <Tooltip label="Share">
        <IconButton
          aria-label="share"
          fontSize="1.25rem"
          icon={<BiPaperPlane />}
          onClick={(e) => {
            e.stopPropagation()
            onShare(submission)
          }}
          isDisabled={isDisabled}
        />
      </Tooltip>
      <Tooltip label="Edit">
        <IconButton
          aria-label="edit-instructions"
          fontSize="1.25rem"
          icon={<BiPencil />}
          onClick={() => onEdit(submission)}
          isDisabled={isDisabled}
        />
      </Tooltip>
      <Tooltip label="Download all">
        <span>
          <DownloadButton
            campaignId={submission.campaignId}
            campaignName={submission.campaignName}
            campaignPublicKey={submission.campaignPublicKey}
            documents={submission.documents}
            isDisabled={isDisabled}
          />
        </span>
      </Tooltip>
    </ButtonGroup>
  )
}
