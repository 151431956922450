import pLimit from 'p-limit'

import { GetDocumentDto } from '~shared/dtos'

import { MAX_CONCURRENT_DOWNLOADS } from '~/constants/config'
import { api } from '~/lib/api'
import { ResponseError } from '~/types/error'
import { encryptionService } from '~/utils/encryption'

export const decryptAndDownload = async (
  documents: GetDocumentDto[],
  campaignPrivateKey: string,
  adminUserId?: number,
) => {
  const limit = pLimit(MAX_CONCURRENT_DOWNLOADS)
  return Promise.all(
    documents.map((doc) =>
      limit(async () => {
        const symmetricKey = encryptionService.decryptKeyAsymmetrically(
          campaignPrivateKey,
          doc.encryptedSymmetricKey,
        )
        if (symmetricKey === null) {
          throw new Error("Could not decrypt the document's symmetric key.")
        }
        const encryptedBlob = await api
          .get(`/users/${adminUserId ?? ''}/documents/${doc.id}`)
          .blob()
          .catch((err: ResponseError) => {
            throw new Error(err.json.message)
          })
        const decryptedFile = await encryptionService.decryptFileSymmetrically(
          symmetricKey,
          encryptedBlob,
        )
        const downloadLink = document.createElement('a')
        downloadLink.download = doc.name
        downloadLink.href = URL.createObjectURL(decryptedFile)
        downloadLink.click()
      }),
    ),
  )
}
