/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { theme as ogpTheme } from '@opengovsg/design-system-react'

// export const textStyles = {
//   h1: {
//     fontSize: '2rem',
//     lineHeight: '2.25rem',
//     weight: 700,
//   },
//   h2: ogpTheme.textStyles['h4'],
//   h3: {
//     ...ogpTheme.textStyles['body-1'],
//     fontWeight: 600,
//   },

//   'caption-2': {
//     ...ogpTheme.textStyles['caption-2'],
//     color: 'neutral.600',
//   },
// }

/**
 * Do not edit directly
 * Generated on Fri, 06 Jan 2023 04:09:42 GMT
 */

export const textStyles = {
  'responsive-display': {
    'heavy-1280': {
      fontWeight: 700,
      lineHeight: '4.5rem',
      fontSize: '4rem',
      letterSpacing: '-0.022em',
      fontFamily:
        'Inter,Trebuchet MS,-apple-system,Arial,BlinkMacSystemFont,sans-serif',
    },
    'heavy-480': {
      fontWeight: 700,
      lineHeight: '4rem',
      fontSize: '3.5rem',
      letterSpacing: '-0.022em',
      fontFamily:
        'Inter,Trebuchet MS,-apple-system,Arial,BlinkMacSystemFont,sans-serif',
    },
    heavy: {
      fontWeight: 600,
      lineHeight: '3rem',
      fontSize: '2.5rem',
      letterSpacing: '-0.022em',
      fontFamily:
        'Inter,Trebuchet MS,-apple-system,Arial,BlinkMacSystemFont,sans-serif',
    },
    'light-1280': {
      fontWeight: 300,
      lineHeight: '4.5rem',
      fontSize: '4rem',
      letterSpacing: '-0.022em',
      fontFamily:
        'Inter,Trebuchet MS,-apple-system,Arial,BlinkMacSystemFont,sans-serif',
    },
    'light-480': {
      fontWeight: 300,
      lineHeight: '4rem',
      fontSize: '3.5rem',
      letterSpacing: '-0.022em',
      fontFamily:
        'Inter,Trebuchet MS,-apple-system,Arial,BlinkMacSystemFont,sans-serif',
    },
    light: {
      fontWeight: 300,
      lineHeight: '3rem',
      fontSize: '2.5rem',
      letterSpacing: '-0.022em',
      fontFamily:
        'Inter,Trebuchet MS,-apple-system,Arial,BlinkMacSystemFont,sans-serif',
    },
  },
  'responsive-heading': {
    'heavy-1280': {
      fontWeight: 600,
      lineHeight: '3.5rem',
      fontSize: '3rem',
      letterSpacing: '-0.022em',
      fontFamily:
        'Inter,Trebuchet MS,-apple-system,Arial,BlinkMacSystemFont,sans-serif',
    },
    'heavy-480': {
      fontWeight: 600,
      lineHeight: '3rem',
      fontSize: '2.5rem',
      letterSpacing: '-0.022em',
      fontFamily:
        'Inter,Trebuchet MS,-apple-system,Arial,BlinkMacSystemFont,sans-serif',
    },
    heavy: {
      fontWeight: 600,
      lineHeight: '2.5rem',
      fontSize: '2rem',
      letterSpacing: '-0.022em',
      fontFamily:
        'Inter,Trebuchet MS,-apple-system,Arial,BlinkMacSystemFont,sans-serif',
    },
    'light-1280': {
      fontWeight: 300,
      lineHeight: '3.5rem',
      fontSize: '3rem',
      letterSpacing: '-0.022em',
      fontFamily:
        'Inter,Trebuchet MS,-apple-system,Arial,BlinkMacSystemFont,sans-serif',
    },
    'light-480': {
      fontWeight: 300,
      lineHeight: '3rem',
      fontSize: '2.5rem',
      letterSpacing: '-0.022em',
      fontFamily:
        'Inter,Trebuchet MS,-apple-system,Arial,BlinkMacSystemFont,sans-serif',
    },
    light: {
      fontWeight: 300,
      lineHeight: '2.5rem',
      fontSize: '2rem',
      letterSpacing: '-0.022em',
      fontFamily:
        'Inter,Trebuchet MS,-apple-system,Arial,BlinkMacSystemFont,sans-serif',
    },
  },
  h1: {
    fontWeight: 600,
    lineHeight: '3rem',
    fontSize: '2.5rem',
    letterSpacing: '-0.022em',
    fontFamily:
      'Inter,Trebuchet MS,-apple-system,Arial,BlinkMacSystemFont,sans-serif',
  },
  h2: {
    fontWeight: 600,
    lineHeight: '2.75rem',
    fontSize: '2.25rem',
    letterSpacing: '-0.022em',
    fontFamily:
      'Inter,Trebuchet MS,-apple-system,Arial,BlinkMacSystemFont,sans-serif',
  },
  h3: {
    fontWeight: 700,
    lineHeight: '2.25rem',
    fontSize: '1.75rem',
    letterSpacing: '-0.019em',
    fontFamily:
      'Inter,Trebuchet MS,-apple-system,Arial,BlinkMacSystemFont,sans-serif',
  },
  h4: {
    fontWeight: 600,
    lineHeight: '2rem',
    fontSize: '1.5rem',
    letterSpacing: '-0.019em',
    fontFamily:
      'Inter,Trebuchet MS,-apple-system,Arial,BlinkMacSystemFont,sans-serif',
  },
  h5: {
    fontWeight: 600,
    lineHeight: '1.75rem',
    fontSize: '1.25rem',
    letterSpacing: '-0.014em',
    fontFamily:
      'Inter,Trebuchet MS,-apple-system,Arial,BlinkMacSystemFont,sans-serif',
  },
  h6: {
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '1.125rem',
    letterSpacing: '-0.014em',
    fontFamily:
      'Inter,Trebuchet MS,-apple-system,Arial,BlinkMacSystemFont,sans-serif',
  },
  'subhead-1': {
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '1rem',
    letterSpacing: '-0.006em',
    fontFamily:
      'Inter,Trebuchet MS,-apple-system,Arial,BlinkMacSystemFont,sans-serif',
  },
  'subhead-2': {
    fontWeight: 500,
    lineHeight: '1.25rem',
    fontSize: '0.875rem',
    letterSpacing: 0,
    fontFamily:
      'Inter,Trebuchet MS,-apple-system,Arial,BlinkMacSystemFont,sans-serif',
  },
  'subhead-3': {
    fontWeight: 600,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    letterSpacing: '0.080em',
    fontFamily:
      'Inter,Trebuchet MS,-apple-system,Arial,BlinkMacSystemFont,sans-serif',
    textTransform: 'uppercase',
  },
  'body-1': {
    fontWeight: 400,
    lineHeight: '1.5rem',
    fontSize: '1rem',
    letterSpacing: '-0.006em',
    fontFamily:
      'Inter,Trebuchet MS,-apple-system,Arial,BlinkMacSystemFont,sans-serif',
  },
  'body-2': {
    fontWeight: 400,
    lineHeight: '1.25rem',
    fontSize: '0.875rem',
    letterSpacing: 0,
    fontFamily:
      'Inter,Trebuchet MS,-apple-system,Arial,BlinkMacSystemFont,sans-serif',
  },
  'body-3': {
    fontWeight: 400,
    lineHeight: '1.75rem',
    fontSize: '1rem',
    letterSpacing: '-0.006em',
    fontFamily:
      'Inter,Trebuchet MS,-apple-system,Arial,BlinkMacSystemFont,sans-serif',
  },
  'caption-1': {
    fontWeight: 500,
    lineHeight: '1rem',
    fontSize: '0.75rem',
    letterSpacing: 0,
    fontFamily:
      'Inter,Trebuchet MS,-apple-system,Arial,BlinkMacSystemFont,sans-serif',
  },
  'caption-2': {
    fontWeight: 400,
    lineHeight: '1rem',
    fontSize: '0.75rem',
    letterSpacing: 0,
    fontFamily:
      'Inter,Trebuchet MS,-apple-system,Arial,BlinkMacSystemFont,sans-serif',
  },
  'code-1': {
    fontWeight: 400,
    lineHeight: '1.25rem',
    fontSize: '0.875rem',
    letterSpacing: 0,
    fontFamily: 'IBM Plex Mono,Courier,Monaco,Courier New,monospace',
  },
  'code-2': {
    fontWeight: 400,
    lineHeight: '1rem',
    fontSize: '0.75rem',
    letterSpacing: 0,
    fontFamily: 'IBM Plex Mono,Courier,Monaco,Courier New,monospace',
  },
  legal: {
    fontWeight: 400,
    lineHeight: '0.75rem',
    fontSize: '0.625rem',
    letterSpacing: 0,
    fontFamily:
      'Inter,Trebuchet MS,-apple-system,Arial,BlinkMacSystemFont,sans-serif',
  },

  // Aliases - can re-organize soon
  title: ogpTheme.textStyles['h4'],
  normal: ogpTheme.textStyles['body-2'],
  subtitle: ogpTheme.textStyles['h6'],
  header: {
    ...ogpTheme.textStyles['subhead-1'],
    fontWeight: 600,
  },
}
