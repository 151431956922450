import { BiDownload } from 'react-icons/bi'
import { useDisclosure } from '@chakra-ui/react'
import { IconButton, IconButtonProps } from '@opengovsg/design-system-react'

import { GetDocumentDto } from '~shared/dtos'

import { useAdminUser } from '~/features/auth/hooks'
import { useCampaignPrivateKey } from '~/features/dashboard'
import { DownloadModal } from '~/features/download'
import { useToast } from '~/hooks/useToast'
import { decryptAndDownload } from '~/utils/download'

export interface DownloadButtonProps {
  campaignId: number
  campaignName: string
  campaignPublicKey: string
  documents: GetDocumentDto[]
  variant?: IconButtonProps['variant']
  isDisabled?: boolean
}

export const DownloadButton = ({
  campaignId,
  campaignName,
  campaignPublicKey,
  documents,
  variant,
  isDisabled = false,
}: DownloadButtonProps): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { adminUser } = useAdminUser()
  const campaignPrivateKey = useCampaignPrivateKey(campaignId)
  const toast = useToast()

  const handleClick = async () => {
    if (campaignPrivateKey === null) {
      onOpen()
      return
    }
    try {
      await decryptAndDownload(documents, campaignPrivateKey, adminUser?.id)
    } catch (err) {
      toast({
        status: 'error',
        description:
          err instanceof Error ? err.message : 'Something went wrong.',
      })
    }
  }

  return (
    <>
      <IconButton
        aria-label={`Download ${
          documents.length === 1 ? documents[0].name : 'all'
        }`}
        icon={<BiDownload />}
        size="md"
        {...(variant && { variant })}
        isDisabled={isDisabled || documents.length === 0}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          handleClick()
        }}
      />
      <DownloadModal
        isOpen={isOpen}
        onClose={onClose}
        campaignId={campaignId}
        campaignName={campaignName}
        campaignPublicKey={campaignPublicKey}
        documents={documents}
      />
    </>
  )
}
