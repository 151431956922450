import { useMemo } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import {
  Button,
  Flex,
  Heading,
  Image,
  Link,
  Spacer,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react'
import dayjs from 'dayjs'

import FileHandoverSvg from '~/assets/FileHandover.svg'
import { routes } from '~/constants/routes'

import { usePublicSubmission, useSubmissionPublicId } from './public.hooks'

export const WelcomePage = (): JSX.Element => {
  const navigate = useNavigate()
  const { submissionPublicId } = useSubmissionPublicId()
  const { submission, isSubmissionLoading, isExpired } =
    usePublicSubmission(submissionPublicId)

  // TODO - fmt human readable
  const expiryInHours = useMemo(() => {
    return dayjs(submission?.expiresAt).endOf('day').diff(dayjs(), 'hour')
  }, [submission])

  if (isSubmissionLoading) return <Spinner />

  //Invalid link
  if (!submission && !isExpired) return <Navigate to={routes.public.error} />

  return (
    <Flex
      flexDir={{ base: 'column', md: 'row' }}
      maxW={{ base: '100vw', md: '1080px' }}
      alignSelf="center"
      align="center"
      justifySelf="center"
      p={{ base: 6, md: 10 }}
    >
      <Image src={FileHandoverSvg} aria-hidden w={{ base: '80%', md: '50%' }} />
      <Spacer />
      <VStack align="stretch" spacing={8} maxW={{ base: 'unset', md: '400px' }}>
        {submission?.contactEmail ? (
          <Heading textAlign="left" as="h6" size="lg">
            {`You've received a request from `}
            <Link href={`mailto:${submission.contactEmail}`}>
              {submission.contactEmail}
            </Link>
            {` to submit your documents.`}
          </Heading>
        ) : (
          <Heading
            textAlign="left"
            as="h6"
            size="lg"
          >{`You've received a request to submit your documents.`}</Heading>
        )}
        {!isExpired ? (
          <>
            <Text textAlign="left">{`Kindly read the instructions before uploading your files.`}</Text>
            <VStack align="stretch">
              <Button onClick={() => navigate('upload')}>Next</Button>
              <Text textAlign="left" textStyle="subhead-1">
                {`Link expires in ~${expiryInHours} hours`}
              </Text>
            </VStack>
          </>
        ) : (
          <Text>{`Your link has expired. If you'd like to submit more documents, contact the public officer who issued you the submission link to request an extension.`}</Text>
        )}
      </VStack>
    </Flex>
  )
}
