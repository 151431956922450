import { BiCheckCircle, BiError, BiTrash, BiXCircle } from 'react-icons/bi'
import {
  Box,
  Center,
  Collapse,
  Flex,
  HStack,
  Progress,
  Text,
} from '@chakra-ui/react'
import { Button, IconButton, Spinner } from '@opengovsg/design-system-react'

import { fmtBytes } from '~/utils/humanReadable'

import { UploadDisplayProps } from './Upload'

export const UploadCard = ({
  fileName,
  sizeInBytes,
  status,
  uploadProgress,
  dismissed,
  errorMessage,
  onCancel,
  onDismiss,
  onRemove,
}: UploadDisplayProps) => {
  const Remove = () => (
    <IconButton
      p={0}
      icon={<BiTrash />}
      colorScheme="red"
      aria-label="remove"
      variant="clear"
      fontSize="1.25rem"
      onClick={onRemove}
    />
  )

  const Dismiss = () => (
    <Button
      variant="clear"
      onClick={onDismiss}
      aria-label={'dismiss'}
      size="md"
    >
      Dismiss
    </Button>
  )

  const Cancel = () => (
    <IconButton
      variant="clear"
      icon={<BiXCircle />}
      onClick={onCancel}
      aria-label={'cancel'}
    />
  )

  const getIcon = () => {
    switch (status) {
      case 'idle':
      case 'encrypting':
      case 'loading':
        return <Spinner fontSize={'1.5rem'} color="primary.400" />
      case 'success':
        return (
          <Box fontSize={'1.5rem'} color="success.400">
            <BiCheckCircle />
          </Box>
        )
      case 'error':
        return (
          <Box fontSize={'1.5rem'} color="red.400">
            <BiError />
          </Box>
        )
      case 'abort':
        return (
          <Box fontSize={'1.5rem'} color="red.400">
            <BiError />
          </Box>
        )
    }
  }

  const getText = () => {
    switch (status) {
      case 'idle':
        return `Encryption queued...`
      case 'loading':
        return `${uploadProgress}%`
      case 'success':
        return `Upload complete`
      case 'error':
        return `Upload error: ${errorMessage}`
      case 'abort':
        return `Upload cancelled`
      case 'encrypting':
        return `Encrypting`
    }
  }

  const getActionIcon = () => {
    switch (status) {
      case 'idle':
      case 'loading':
      case 'encrypting':
        return onCancel ? <Cancel /> : <></>
      case 'success':
        return onRemove ? <Remove /> : onDismiss ? <Dismiss /> : <></>
      case 'abort':
      case 'error':
        return onDismiss ? <Dismiss /> : <></>
    }
  }

  return (
    <Collapse in={!dismissed} unmountOnExit={true} animateOpacity>
      <Flex
        bg="neutral.200"
        py="0.500rem"
        px="1rem"
        borderRadius="0.25rem"
        align="center"
      >
        <Box pl={1} pr={4}>
          {getIcon()}
        </Box>
        <Flex flexDir="column" aria-hidden w="100%" gap={0.5} align="start">
          <Text textStyle="subhead-2" color="secondary.500">
            {fileName}
          </Text>
          <Text textStyle="caption-2" color="secondary.500" mt={-1}>
            {fmtBytes(sizeInBytes)}
          </Text>
          {status === 'loading' ? (
            <HStack>
              <Progress
                borderRadius="0.125rem"
                value={uploadProgress}
                w="100%"
                size="sm"
              />
              <Text textStyle="caption-1" color="secondary.500">
                {getText()}
              </Text>
            </HStack>
          ) : (
            <Text textStyle="caption-1" color="secondary.500">
              {getText()}
            </Text>
          )}
        </Flex>
        <Center pl={3}>{getActionIcon()}</Center>
      </Flex>
    </Collapse>
  )
}
